body {
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	background: #f3f3f4 !important;
}

@page {
	size: auto;
	/* auto is the initial value */
	margin: 15mm 0 10mm 0;
}

a {
	text-decoration: none;
	/* color:rgba(0, 0, 0, 0.54) ; */
	color: #f3f3f4;
}

.dashboard {
	background: #f3f3f4;
	width: 100%;
}

.imgT {
	width: 10px;
	min-width: 45px;
	height: 10px;
	min-height: 45px;
	cursor: pointer;
	border-radius: 24px;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

}

.login {
	background: radial-gradient(ellipse farthest-corner at center top, #d8dbe2 0%, #a9bcd0 100%);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 100vh;
	/* margin:  0 50px; */

}

button {
	border-radius: 20px;
	border: 1px solid #00cccc;
	background-color: #00cccc;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
	cursor: pointer;
}


.form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}

input {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
}

.container {
	background-color: #808b97;
	;
	border-radius: 10px;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	position: relative;
	overflow: hidden;
	width: 768px;
	max-width: 100%;
	min-height: 480px;
}

.form-container {
	position: absolute;
	top: 0;
	height: 100%;
}

.log-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}





.overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
}


.overlay {
	/* background: #FF416C;
	background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
	background: linear-gradient(to right, #FF4B2B, #FF416C); */
	background: #808b97;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
}

.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	/* padding: 0 40px; */
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
}


.overlay-right {
	right: 0;
}


.social-container {
	margin: 50px 0;
}

.social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

.sidebar-icon {
	width: 1.5em;
	height: 20px;
	color: #ffffff;
}

.sidebar-link active {
	border-left: 3px solid black;
}

.sidebar-link {
	border-left: none;
}

.togglePassword {
	position: absolute;
	top: 250px;
	right: 55px;
}

/* media queries start */

@media only screen and (max-width: 360px) {

	.overlay-container {
		display: none;
	}

	.togglePassword {
		position: absolute;
		top: 135px;
		right: 38px;
		font-size: 12px;
	}

	.Login-img {
		width: 100%;

	}

	button {
		font-size: 12px;
		padding: 7px 12px;
		letter-spacing: normal;
	}

	input::placeholder {
		font-size: 12px;
	}

	.overlay-container {
		display: none !important;
	}

	.log-in-container {
		width: 100%;
	}

	.container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 348px;
		max-width: 100%;
		min-height: 240px;
	}

}
.MobLogo{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 15%;
}
.MobLogoImg{	
	height: 100%;
}
@media only screen and (width >=501px) {

	.MobLogo{
		display: none;
	}


}
@media only screen and (min-width: 320px) and (max-width: 500px) {
	
	.container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 348px;
		max-width: 100%;
		min-height: 320px;
	}

	.togglePassword {
		position: absolute;
		top: 180px;
		right: 47px;
		font-size: small;
	}

	.Login-img {
		width: 100%;

	}
	
	button {
		font-size: 11px;
		padding: 7px 16px;
		letter-spacing: normal;
	}

	.overlay-container {
		display: none !important;
	}

	.log-in-container {
		width: 100%;
	}

}

.passwordForget {
	color: #00cccc;
	/* margin-top: 30px; */
	text-decoration: underline;
}



@media only screen and (max-width: 708px) {

	.largeScreen-btn {
		display: none !important;
		border-radius: 40px;
		font-size: large;
	}

}

@media only screen and (width >=708px) {

	.smallScreen-btn {
		display: none !important;
	}


}

@media only screen and (min-width: 501px) and (max-width: 800px) {
	
	.container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		position: fixed;
		width: 558px;
		max-width: 100%;
		min-height: 340px;
	}

	.togglePassword {
		position: absolute;
		top: 183px;
		right: 45px;
		font-size: small;
	}
}

/* media queries end */

table {
	font-family: arial, sans-serif;
	border-collapse: collapse;
	width: 650px;
}

.tdInvoice,
.thInvoice {
	border: 1px solid #0c0c0c;
	/* text-align: left; */
	padding: 0px 8px;
	/* padding-bottom: 28px; */
}

.rdrDefinedRangesWrapper {
	display: none;
}

.MuiDataGrid-columnHeaderTitle {
	font-weight: 600 !important;
	color: #365b6d;
}

.diviDer {
	margin-top: 5px !important;
}

.vat-qr {
	width: 135px;
}



input:focus-visible {
	outline: none !important;
}



.Sidebar-overlay {
	position: fixed;
	top: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(7, 7, 7, 0.5);
	z-index: 1050;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media only screen and (min-width: 900px) {
	.backdrop {
		display: none;
	}
}

.tableSimple .simpleheader{
	border-top: 2px dashed #000;
	border-bottom: 2px dashed #000;
  }
  .tableSimple {
	text-align: center;
	width: 100%;
  }
  .tableSimple .totalSimple td:first-of-type {
	border-top: none;
	border-bottom: none;
  }
  .tableSimple .totalSimple td {
	border-top: 2px dashed #000;
	border-bottom: 2px dashed #000;
  }
  .tableSimple .net-amount td:first-of-type {
	border-top: none;
  }
  .tableSimple .net-amount td {
	border-top: 2px dashed #000;
  }
  .tableSimple .net-amount{
	border-bottom: 2px dashed #000;
  }

  .simpleHtmlInvoice{
	width: 40% !important;
  }
  .simpleheader th {
	font-size: 14px;
  }

  @media only screen and (max-width: 900px){
	.simpleHtmlInvoice{
		width: 70% !important;
	  }
  }

  @media only screen and (max-width: 500px){
	.simpleHtmlInvoice{
		width: 90% !important;
	  }
  }

  @media print {
	.printTable {
	  width: 100% !important;
	  table-layout: fixed !important;
	}
  
	.printTable th, .printTable td {
	  word-wrap: break-word;
	  white-space: normal; 
	  padding: 5px;
	  border: 1px solid #000;
	}
	
	.noPrint {
	  display: none;
	}
  
	.pageBreak {
	  page-break-before: always;
	}
  }
  
  
  